import React from 'react';
import { Helmet } from 'react-helmet';
import { Container, Row, Col } from 'react-bootstrap';
import $ from 'jquery';

// Components
import SEO from '../components/seo';
import Header from '../components/header';
import Hero from '../components/hero';
import Introduction from '../components/introduction';
import Contact from '../components/contact';
import Footer from '../components/footer';

// Data
import data from '../data/mission-vision.json';

// Images
import unternehmen_img from '../assets/img/unternehmen-img.png';
import joshua_raabe_img from '../assets/img/joshua-raabe-img.png';
import CookieConsent from '../components/cookieConsent';

export default function MissionVision() {
	React.useEffect(() => {
		if (window.location.hash.length > 0) {
			window.scroll(0, 0);

			setTimeout(function () {
				window.scroll(0, 0);
			}, 1);

			$('html,body').animate(
				{
					scrollTop: $(window.location.hash).offset().top - 80 + 'px',
				},
				800,
				'swing'
			);
		}
	}, []);

	return (
		<div className="mlg_site-container mlg_page-home">
			<SEO title="Mission / Vision" />

			<Helmet>
				<style type="text/css">{`
          .mlg_site-hero img {
            margin-bottom: -58px;
          }
          .mlg_intro {
            margin-bottom: 0 !important;
          }
          .mlg_intro .mlg_heading p {
            color: #000000;
            margin-top: 45px;
            font-size: 16px;
            line-height: 30px;
            padding-right: 0 !important;
          }
          .mlg_custom-10005 {
            padding-top: 9.75rem;
          }
          .mlg_testimonial {
            margin-bottom: 0;
          }
          .mlg_testimonial .mlg_t-author {
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .mlg_testimonial .mlg_t-author .mlg_t-author-content {
            text-align: left;
          }
          .mlg_testimonial .mlg_t-author .mlg_t-a-image {
            max-width: 162px;
          }
          .mlg_testimonial .mlg_t-author .mlg_t-a-image:before {
            right: 0;
          }
          .mlg_testimonial .mlg_t-author .mlg_t-a-name {
            padding-top: 0;
          }
          @media (max-width: 1200px) {
            .mlg_site-hero .mlg_heading p br {
              display: none !important;
            }
          }
          @media (max-width: 991.98px) {
            .mlg_site-hero .row {
              height: 700px;
            }
            .mlg_custom-10005 {
              padding-top: 6.75rem;
            }
            .mlg_custom-10005 img {
              margin-top: 96px;
            }
            .mlg_testimonial .mlg_t-author .mlg_t-a-image {
              max-width: 116px;
              margin-right: 20px;
            }
            .mlg_testimonial .mlg_t-author .mlg_t-a-image:before {
              right: -9px;
            }
          }
          @media (max-width: 768px) {
            .mlg_site-hero img {
              margin-bottom: -138px !important;
            }
          }
          @media (max-width: 576px) {
            .mlg_site-hero .row {
              height: 450px;
            }
            .mlg_site-hero img {
              margin-bottom: -71px !important;
            }
          }
        `}</style>
			</Helmet>

			<Header />

			<main className="mlg_site-content">
				<Hero {...data.hero} />

				<Introduction {...data.introduction} />

				<section className="mlg_section pt-6-75rem mlg_price-calculator-bg">
					<Container className="text-center">
						<Row>
							<Col xl={{ span: 8, offset: 2 }} lg={{ span: 8, offset: 2 }}>
								<h2 style={{ marginBottom: '48px', fontSize: '50px', lineHeight: '65px' }}>Der Name "THNG"</h2>

								<p>
									Der Name THNG steht für Thing. Englisch für Sache, Angelegenheit, Thema. Kann auch
									deutsch, ohne „th“ ausgesprochen werden. Der Begriff Thing ist nämlich schon sehr
									alt. Er stammt aus dem nordisch-skandinavischen Sprachgebrauch und war - etwa in
									Island - schon vor weit mehr als 1000 Jahren die Bezeichnung für Volksversammlungen.
									Thing steht für eine frühe Form der demokratischen Mitbestimmung. Daran will THNG
									als die moderne und digitale Form der Beteiligung anknüpfen.
								</p>
							</Col>
						</Row>
					</Container>

					<Container className="mlg_custom-10005">
						<Row>
							<Col lg={6} md={12}>
								<h2 style={{ marginBottom: '48px', fontSize: '50px', lineHeight: '65px' }}>Das Unternehmen</h2>

								<p>
									Das Start-up THNG wurde 2020 in Leonberg gegründet und beschäftigt derzeit 10
									Mitarbeiter. Das Team besteht aus erfahrenen Berater-Experten und IT-Koryphäen, die
									bereits seit vielen Jahren an Lösungen arbeiten, die einen positiven Beitrag zur
									Gesellschaft leisten sollen. CEO des Start-ups ist der Digitalexperte Joshua Raabe.
									Als ehemaliger Vorstandsvorsitzender eines IT-Beratungsunternehmens ist er in der
									Tech-Branche zu Hause. Mit THNG hat er sich nun seinen eigenen Traum verwirklicht.
								</p>
							</Col>

							<Col lg={6} md={12} className="text-right text-md-center" style={{ paddingTop: '10px' }}>
								<img src={unternehmen_img} alt="Unternehmen" />
							</Col>
						</Row>
					</Container>
				</section>

				<section className="pt-6-75rem pb-6-75rem">
					<Container>
						<Row>
							<Col xl={{ span: 8, offset: 2 }} lg={{ span: 8, offset: 2 }}>
								<div className="mlg_testimonial text-center">
									<div className="mlg_t-content">
										<p>
											THNG ist die optimale Lösung, um Menschen mit zeitgemäßen Mitteln zu
											erreichen und dazu einzuladen, ihre Zukunft aktiv mitzugestalten. Wir sind
											uns sicher, dass die App Menschen zusammenbringen wird.
										</p>
									</div>
									<div className="mlg_t-author">
										<div className="mlg_t-a-image">
											<img src={joshua_raabe_img} alt="Joshua Raabe" />
										</div>
										<div className="mlg_t-author-content">
											<h5 className="mlg_t-a-name">Joshua Raabe</h5>
											<p className="mlg_t-a-position">CEO THNG</p>
										</div>
									</div>
								</div>
							</Col>
						</Row>
					</Container>
				</section>

				<Contact />
			</main>

			<Footer />

			<CookieConsent />
		</div>
	);
}
